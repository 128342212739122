import { navigate } from "gatsby"
import { Button, Form, TextInput} from 'carbon-components-react';
import React, {useState} from 'react';
import { useGoogleReCaptcha } from 'react-google-recaptcha-v3';
import validateEmail from '../util/validateEmail'

import {
  siteMetadata
} from "../../gatsby-config"

async function jsonRequest(endpoint, method, payload){
  const url = `${siteMetadata.drupalUrl}/${endpoint}`;
  const init = {
    method: method,
    headers: {
      'Accept': 'application/json',
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(payload)
  }
  return fetch(url,init);
}

const RegistryForm = ({pageContext, location}) => {
  const [fullName, setFullName] = useState("")
  const [email, setEmail] = useState("")
  const [newPass, setNewPass] = useState("")
  const [confirmedPass, setConfirmedPass] = useState("")
  const [message, setMessage] = useState(null)
  const { executeRecaptcha } = useGoogleReCaptcha();

  const handleInputChange = event => {
    const target = event.target;
    const value = target.value;
    const name = target.name;
    console.log('handleInputChange', name, value);
    switch(name) {
      case 'field_full_name':
        setFullName(value)
        break
      case 'email':
        setEmail(value)
        break
      case 'newPass':
        setNewPass(value)
        break
      case 'confirmedPass':
        setConfirmedPass(value)
        break
      default:
        console.log('field has no state')
    }
  }


  const handleSubmit = async event => {
    event.preventDefault();
    event.persist();
    if (newPass !== confirmedPass) {
      setMessage("Passwords do not match");
    } else {
      try {
        // const valid = await recaptchaSubmit(executeRecaptcha)
        if(fullName && fullName.length > 0 && email && validateEmail(email)){
          const payload = {
            field_full_name: [{value: fullName}],
            name: [{value: email}],
            mail: [{value: email}],
            pass: [{value: newPass}],
          };
          const response = await jsonRequest("user/register?_format=json", "POST", payload);
          if(response.ok){
            navigate('/login')
          }else{
            throw new Error("Unable to create account.")
          }
        }else{
          throw new Error("Please check all the fields and try again")
        }
      } catch (err) {
        setMessage(err.message);
      }
    }
  }

  return (
    <>
      <div>
        <p>Fill out the form below to create an account</p>
      </div>
      <Form className="register-form" onSubmit={handleSubmit}>
        {message}
        <TextInput
          light
          required
          placeholder="Full Name"
          type="text"
          name="field_full_name"
          value={fullName}
          onChange={handleInputChange}
        />
        <TextInput 
          light
          required
          placeholder="Email"
          type="email"
          name="email"
          value={email}
          onChange={handleInputChange}
        />
        <TextInput 
          light
          required
          placeholder="Password"
          type="password"
          name="newPass"
          value={newPass}
          onChange={handleInputChange}
        />
        <TextInput 
          light
          required
          placeholder="Confirm Password"
          type="password"
          name="confirmedPass"
          value={confirmedPass}
          onChange={handleInputChange}
        />
        <Button type="submit">Submit</Button>
      </Form>
    </>
  )
}

const RegisterPage = ({pageContext, location}) => {
  pageContext = {
    ...pageContext,
    frontmatter: { title: "HEED Application", tabs: ["Profile", "Application"] },
  };
  return (
    <RegistryForm
      pageContext={pageContext}
      location={location}
    />
  );
}

export default RegisterPage
